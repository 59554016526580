import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posting - Bewerbungsprozess",
  "description": "Wie gelangt der Bewerber von einem externen Anzeigenportal auf meine Karriereseite?",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Die meisten Stellenportale unterstützen eine Verlinkung der Bewerbungsfunktion direkt zur Karriereseite des Unternehmens. Bei jedem Posting wird diese Bewerbungs-URL mitgegeben.
So ist für die meisten Portale der Bewerbungsprozess komplett durchgängig – bspw. schön zu sehen auf Indeed `}<a parentName="p" {...{
        "href": "https://de.indeed.com/viewjob?jk=74784556ccccd673&tk=1c6ro7uam16vo0hk&from=serp&vjs=3"
      }}>{`https://de.indeed.com/viewjob?jk=74784556ccccd673&tk=1c6ro7uam16vo0hk&from=serp&vjs=3`}</a>{` .`}</p>
    <p>{`Es kann allerdings nicht ausgeschlossen werden dass sich ein Bewerber per Mail bei Ihnen bewirbt oder eine Plattform auf eine Bewerbung auf den eigenen Bewerbungsweg besteht.`}</p>
    <p>{`Hier kann die Bewerbung entweder akzeptiert oder mit Hinweis auf das Portal (automatisiert oder manuell) zurückgewiesen werden. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      